import React, { useEffect, useState } from "react"
import uniqBy from "lodash/uniqBy"
import sortBy from "lodash/sortBy"
import { Badge, Spinner } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

import CONFIG from "../../config"
import ConceptCard from "./concept"
import ConceptModal from "./conceptModal"
import PassageCard from "./passage"
import PlayButton from "../play/button"
import ZeroState from "../common/zeroState"
import { Concepts_concepts } from "../../hasura/queries/types/Concepts"
import { CurriculumState, curriculumSelector } from "../../hasura/slices/curriculum"
import { Passage, Token } from "../../hasura/slices/passage"
import { PassagesForUser_passages } from "../../hasura/queries/types/PassagesForUser"
import { QueryName } from "../../hasura/queryNames"
import { UserEvent } from "../../lib/userEventTypes"
import { User_users_by_pk_experience } from "../../hasura/queries/types/User"
import { conceptSelector, ConceptState, fetchConceptsAction } from "../../hasura/slices/concept"
import { insertUserEventAction, userSelector, UserState } from "../../hasura/slices/user"

import {
  getImagesForUser,
  instanceOfConcept,
  instanceOfConceptExperience,
  instanceOfPassage,
  parseIdFrom,
  searchQueryParams,
} from "../../lib/helpers"

const ALPHABET = "abcdefghijklmnopqrstuvwxyz".split("")

type Entity = PassagesForUser_passages | Passage | Concepts_concepts | User_users_by_pk_experience

export default function Library() {
  const dispatch = useDispatch()

  const { accessToken, user, isAdmin, isStudent, isTeacher }: UserState = useSelector(userSelector)
  const { concepts, isQuerying }: ConceptState = useSelector(conceptSelector)
  const { isCore, curriculum }: CurriculumState = useSelector(curriculumSelector)

  const [displayConceptId, setDisplayConceptId] = useState<number | undefined>(parseInt(searchQueryParams("c") || "", 10))
  const [entities, setEntities] = useState<Entity[]>([])
  const [character, setCharacter] = useState<string>("a")

  const imagesForUser = getImagesForUser(user)

  const concept = concepts?.find((c) => c.id === displayConceptId)

  /*
    Effects
  */

  useEffect(() => {
    if (!user) return

    const filteredConcepts = isStudent
      ? sortBy(uniqBy(user?.experience.filter((e) => e.concept) || [], "concept.display_name"), "concept.display_name")
      : sortBy(
          concepts?.filter(
            (c) => (!isCore || c.root_appearances.length) && c.definition?.length && c.display_name.toLowerCase().startsWith(character)
          ) || [],
          "obscurity"
        )

    // @ts-ignore
    setEntities(filteredConcepts)
    document.getElementById("library-scroll")?.scrollTo(0, 0)
  }, [concepts, character])

  useEffect(() => {
    if (isTeacher) {
      localStorage.setItem("hasViewedLibrary", "true")
    }
  }, [isTeacher])

  useEffect(() => {
    if (!accessToken) return

    dispatch(fetchConceptsAction(accessToken, curriculum?.id))
    const event = { user_id: parseIdFrom(accessToken), user_event_type_id: UserEvent.ViewedLibrary }
    dispatch(insertUserEventAction(accessToken, event))
  }, [accessToken, isAdmin])

  return (
    <div style={{ height: `calc(100vh - ${CONFIG.HEADER_HEIGHT}px)` }} className="">
      {concept && <ConceptModal setDisplayConceptId={setDisplayConceptId} concept={concept} />}

      <div className="vw-100 px border-bottom py-4">
        <div className="max-width-1100px mx-auto d-flex">
          <div className="d-flex align-items-center flex-even">
            <h1 className="text-xl bold m-0">{isStudent ? "My " : ""}Library</h1>

            {!isStudent && (
              <div className="d-flex ml-5">
                {ALPHABET.map((letter) => {
                  const isSameLetter = letter === character
                  return (
                    <p
                      key={letter}
                      onClick={() => setCharacter(letter)}
                      className={`m-0 text-m text-uppercase px-1 ${isSameLetter ? "" : "text--gray4 hover-primary pointer"}`}
                    >
                      {letter}
                    </p>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="max-width-1100px mx-auto">
        {isQuerying[QueryName.FetchConcepts] ? (
          <Spinner className="mt-2" color="primary" />
        ) : (
          <div style={{ paddingBottom: "125px" }}>
            {isTeacher && user?.grades && (
              <div className="text-s text--gray8 pt-2 pb-1">
                Curriculum adapted to your students in
                <Badge className="ml-1 text-xs" color="primary">
                  Grade {user.grades.startsWith(" - ") || user.grades.endsWith("- ") ? user.grades.match(/-?\d+/)?.[0] : user.grades}
                </Badge>
              </div>
            )}

            {!entities.length && <ZeroState bodyOne="Your library is empty." bodyTwo="Click play below to begin." minHeight="50vh" />}

            <div id="library-scroll" style={{ height: `calc(100vh - 125px)` }} className="d-flex flex-wrap gap-20px pt-3 pb-5 overflow-scroll">
              {entities.map((entity, idx) => {
                if (instanceOfPassage(entity)) {
                  const tokenLemmas = (entity.annotated.tokens || []).map((t: Token) => t.lemma)
                  return (
                    <PassageCard
                      concepts={(concepts || []).filter((c) => tokenLemmas.includes(c.display_name))}
                      experience={user?.experience.find((e) => e.passage_id === entity.id)}
                      imagesForUser={imagesForUser}
                      key={idx}
                      passage={entity}
                    />
                  )
                } else if (instanceOfConcept(entity)) {
                  return (
                    <ConceptCard
                      concept={entity}
                      imagesForUser={[]}
                      isAdmin
                      key={idx}
                      obscurity={entity.obscurity}
                      setDisplayConceptId={setDisplayConceptId}
                    />
                  )
                } else if (instanceOfConceptExperience(entity)) {
                  return (
                    <ConceptCard
                      concept={entity.concept!}
                      correct={entity.correct}
                      imagesForUser={imagesForUser}
                      isAdmin={false}
                      key={idx}
                      obscurity={entity.concept!.obscurity}
                      seen={entity.seen}
                      setDisplayConceptId={setDisplayConceptId}
                    />
                  )
                }
              })}
            </div>
          </div>
        )}

        {isStudent && (
          <PlayButton
            userLevel={user!.level}
            userStars={user!.stars}
            leaderboardsDisabled={Boolean(user?.classroom?.teacher.leaderboards_disabled)}
          />
        )}
      </div>
    </div>
  )
}
